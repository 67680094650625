var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-links"},[_c('div',{staticClass:"footer-links-grid content--max"},[_c('div',{staticClass:"footer-links-grid__item"},[_vm._m(0),_vm._v(" "),_c('div',[_c('a',{attrs:{"href":"/the-hub","title":"The Hub","target":"_blank","rel":"noopener"},on:{"click":function($event){$event.preventDefault();return _vm.handleTheHubClick.apply(null, arguments)}}},[_vm._v("News & Blog")])]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7)]),_vm._v(" "),_vm._m(8),_vm._v(" "),_vm._m(9),_vm._v(" "),_vm._m(10),_vm._v(" "),_vm._m(11),_vm._v(" "),_c('div',{staticClass:"footer-links-grid__item"},[_c('div',[_vm._v("© "+_vm._s(_vm.year)+" magazine.co.uk")]),_vm._v(" "),_c('div',[_c('img',{attrs:{"src":`${_vm.$config.imgixURL}/wysiwyg/footercards.png?auto=format&w=231`,"width":"210px","height":"75px","alt":"Accepted Payments","loading":"lazy"}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('strong',[_vm._v("Useful links")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"title":"Best selling magazines","href":"/best-selling-magazine-subscriptions"}},[_vm._v("Best selling magazines")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"title":"Magazines for women","href":"/magazine-subscriptions-for-women"}},[_vm._v("Magazines for women")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"title":"Magazines for men","href":"/magazine-subscriptions-for-men"}},[_vm._v("Magazines for men")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"title":"Sale offers","href":"/discounted-magazine-sale-offers"}},[_vm._v("Sale offers")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"title":"Gift Subscriptions","href":"/magazine-subscription-gift-ideas"}},[_vm._v("Gift Subscriptions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"title":"Digital magazines","href":"https://pocketmags.com/","target":"_blank","rel":"noopener"}},[_vm._v("Digital magazines")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"title":"What is a magazine subscription","href":"/why-a-magazine-subscription "}},[_vm._v("What is a magazine subscription")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-links-grid__item"},[_c('div',[_c('strong',[_vm._v("Help")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"FAQs","href":"/frequently-asked-questions"}},[_vm._v("FAQs")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Terms and Conditions","href":"/terms-and-conditions"}},[_vm._v("Terms & Conditions")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Delivery","href":"/delivery-policy"}},[_vm._v("Delivery")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Bulk orders & Corporate sales","href":"/corporate-sales"}},[_vm._v("Bulk orders & Corporate sales")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Contact us","href":"/contact"}},[_vm._v("Contact us")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Sitemap","href":"/sitemap"}},[_vm._v("Sitemap")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"href":"https://order-tracker.magazine.co.uk/#/","target":"_blank","title":"Order Tracker","rel":"noopener"}},[_vm._v("Order Tracker")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-links-grid__item"},[_c('div',[_c('strong',[_vm._v("Our Other Sites")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Jellyfish","href":"https://www.jellyfish.com","target":"_blank","rel":"noopener"}},[_vm._v("Jellyfish")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Pocketmags","href":"https://pocketmags.com/","target":"_blank","rel":"noopener"}},[_vm._v("Pocketmags")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-links-grid__item"},[_c('div',[_c('strong',[_vm._v("Corporate")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"About Us","href":"/about-us"}},[_vm._v("About us")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Privacy Policy","href":"/privacy-notice"}},[_vm._v("Privacy Policy")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"California & US Privacy Info","href":"/california-and-us-privacy-info"}},[_vm._v("California & US Privacy Info")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Cookie Policy","href":"/cookie-policy"}},[_vm._v("Cookie Policy")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"My Privacy Choices","href":"#","onclick":"window.Cookiebot.show()"}},[_vm._v("My Privacy Choices")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Jellyfish","href":"https://www.jellyfish.com","target":"_blank","rel":"noopener"}},[_vm._v("Jellyfish")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-links-grid__item"},[_c('div',[_c('strong',[_vm._v("Sell with us")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Publisher FAQs","href":"/publishers"}},[_vm._v("Publisher FAQs")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Apply to sell","href":"/publishers"}},[_vm._v("Apply to sell")])]),_vm._v(" "),_c('div',[_c('a',{attrs:{"title":"Affiliates","href":"/affiliates"}},[_vm._v("Affiliates")])])])
}]

export { render, staticRenderFns }