<script>
// import LazyHydrate from 'vue-lazy-hydration'
import differenceWith from 'lodash/differenceWith'
import xss from 'xss'
import _find from 'lodash/find'
import { formatCurrency } from '~/lib/util/basket'
import { parsePDPPathURL, sliceDataForDebug } from '~/lib/util/page'
import basketMixin from '~/mixins/basket.vue'
import gtmProductPageMixin from '@/mixins/gtm/product-page'
import { getPDPMicrodataJSONLD, getBreadcrumbsMicrodataJSONLD, getFAQMicrodataJSONLD } from '~/lib/util/microdata'
import { getFAQs } from '~/lib/util/faqs'

import { getStatusCodeFromPath, callAWSFrontendAPI } from '~/lib/util/aws'

export default {
  name: 'Product',
  serverCacheKey() {
    return false
  },
  filters: {
    sanitize(value) {
      if (!value) {
        return ''
      }
      return xss(value)
    },
    removeExtraNewLine(html) {
      if (html) {
        return html
          .replace(/cdn01.magazine.co.uk\/media/, 'magazine.imgix.net')
          .replace(/margin-left: 3px/gi, '')
          .replace(/margin-right: 3px/gi, 'margin-right: 10px')
          .replace(/<p>&nbsp;<\/p>/gi, '')
      }
    },
    parseHTML(value) {
      if (!value) {
        return ''
      }
      // console.log('parseHTML original=', value)
      let sup = value
      // remove comments
      sup = sup.replace(/<!--.*?-->/gs, '')
      sup = sup.replace(/<div/gs, '<span')
      sup = sup.replace(/div>/gs, 'span>')

      sup = sup.replace(/<em/gs, '<span style="font-style: italic;" ')
      sup = sup.replace(/em>/gs, 'span>')

      // remove first <p> tag level
      sup = sup.replace(/(^<p>|<\/p>$)/gi, '')

      // remove all other <p> tags
      sup = sup.replace(/(<p>|<\/p>)/gi, '<br/>')
      // console.log('parseHTML final=', sup)
      return sup
    }
  },

  components: {
    // LazyHydrate,
    UpsellProduct: () => import('~/components/common/list/Product.vue'),
    Social: () => import('~/components/common/Social.vue'),
    Offers: () => import('~/components/common/product/Offers.vue'),
    MobileOfferDefault: () => import('~/components/common/product/MobileOfferDefault.vue'),
    PDPReviews: () => import('~/components/PDP/PDPReviews.vue'),
    InstantAccess: () => import('~/components/common/product/InstantAccess'),
    BImageImgix: () => import('~/components/common/BImageImgix'),
    PaymentImages: () => import('~/components/common/product/PaymentImages.vue'),
    PDPFAQs: () => import('~/components/PDP/PDPFAQs.vue'),
    PDPWaysToPay: () => import('~/components/PDP/PDPWaysToPay.vue'),
    PDPWhatSInside: () => import('~/components/PDP/PDPWhatSInside.vue'),
    PromotionsSitewideMobile: () => import('~/components/common/promotions/PromotionsSitewideMobile.vue'),
    TheMagazineImage: () => import('~/components/common/TheMagazineImage')
  },
  mixins: [basketMixin, gtmProductPageMixin],
  layout: 'default',
  watchQuery: ['layout'],
  async fetch() {
    // Skipping any ECard, Gift-card, EGift-card and Pocketmags Plus pages
    if (
      this.$route.path.includes('/ecard') ||
      this.$route.path.includes('/magazines/gift-cards') ||
      this.$route.path.includes('/magazines/e-giftcards') ||
      this.$route.path.includes('/magazines/pocketmagsplus') ||
      this.$route.path.includes('href')
    ) {
      return
    }

    const status = getStatusCodeFromPath({ context: this })
    if (status !== 200) {
      if (process.server) {
        this.$nuxt.context.res.statusCode = status
      }
      this.$store.commit('UI/statusCode', status, { root: true })
      return
    }

    // Look for an available Query String and retrieve the URL path
    const path = this.$route.path
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=PDP')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)
    console.log('AWSAPIGatewayURL=', this.$nuxt.$config.AWSAPIGatewayURL)

    const pageURL = parsePDPPathURL(path)
    console.log(`fetch PRODUCT path=${path} -> page=${pageURL}`)

    this.dataUI = {
      ...(await callAWSFrontendAPI({ context: this, path: pageURL, query, useCache, type: 'PRODUCTPAGE' }))
    }

    if (this.dataUI) {
      console.log('SKU=', this.dataUI.magazine.sku)
      if (!this.dataUI?.offers && this.dataUI?.magazine?.PKMGOnly) {
        this.$store.commit('ADD_BREADCRUMBS', { path, magazine: this.dataUI?.magazine })
        this.dataUI.offers = []
        console.log('NO OFFERS and PKMGOnly=true')
        return
      }

      if (!this.dataUI?.offers && !this.dataUI?.magazine?.PKMGOnly) {
        this.dataUI.offers = []
        if (process.server) {
          this.$nuxt.context.res.statusCode = 500
        }
        console.log('NO OFFERS and PKMGOnly=false')
        this.$store.commit('UI/statusCode', 500, { root: true })
        return
      }

      // Used to map the current offer types(PRINT, INTERNATIONAL, DIGITAL, SINGLE_ISSUES) to a map of the current tabs available
      this.offersTabs.PRINT.available = this.dataUI?.offers?.PRINT || false
      this.offersTabs.INTERNATIONAL.available = this.dataUI?.offers?.INTERNATIONAL || false
      this.offersTabs.DIGITAL.available = this.dataUI?.offers?.DIGITAL || false
      this.offersTabs.SINGLE_ISSUES.available = this.dataUI?.offers?.SINGLE_ISSUES || false
    }
    // Breadcrumbs
    const magazine = this.dataUI.magazine
    this.$store.commit('ADD_BREADCRUMBS', { path, magazine })
  },

  fetchOnServer: true,
  // multiple components can return the same `fetchKey` and Nuxt will track them both separately
  // fetchKey: 'pdp',
  // Set the minimum executing time in milliseconds (to avoid quick flashes)
  // fetchDelay: 5000,
  data() {
    return {
      isReview: 0,
      isLoading: false,
      displayEUOffers: process.env.DISPLAY_EU_OFFERS === 'true',
      isFromMobile: this.$ua.isFromIphone() || this.$ua.isFromAndroidMobile(),
      offersTabs: {
        PRINT: {
          available: this.dataUI?.offers?.PRINT || false,
          active: false
        },
        INTERNATIONAL: {
          available: this.dataUI?.offers?.INTERNATIONAL || false,
          active: false
        },
        DIGITAL: {
          available: this.dataUI?.offers?.DIGITAL || false,
          active: false
        },
        SINGLE_ISSUES: {
          available: this.dataUI?.offers?.SINGLE_ISSUES || false,
          isAactive: false
        }
      },
      dataUI: {},
      sitewidemobile: this.$store.getters['utility/sitewidemobile'] || { enabled: false }
    }
  },

  computed: {
    isOnlyDigitalMagazine() {
      if (this.dataUI?.offers?.DIGITAL && Object.keys(this.dataUI?.offers) === 1) {
        return true
      }

      return false
    },
    bestSellersValues() {
      // Remove all prods already in the basket
      const list = this.$store.getters['utility/bestsellers']
      const cart = (this.$store.state.basket && this.$store.state.basket.items) || []
      const newProdsList = differenceWith(list, cart, function(a, b) {
        return a.offer.productId === b.offer.productId
      })
      return (newProdsList && newProdsList.slice(0, 4)) || []
    },
    upsellValues() {
      return (this.dataUI.upsell && this.dataUI.upsell.slice(0, 4)) || []
      // // Remove all prods already in the basket
      // const list = this.dataUI.upsell
      // const basket = this.$store.state.basket ? this.$store.state.basket.items : []
      // const newProdsList = differenceWith(list, basket, function(a, b) {
      //   return a.offer.productId === b.offer.productId
      // })
      // const newProdsList2 = differenceWith(newProdsList, this.bestSellersValues, function(a, b) {
      //   return a.offer.productId === b.offer.productId
      // })
      // return newProdsList2.slice(0, 4)
    },
    instantAccess() {
      if (!this.dataUI.offers || !this.dataUI.offers.PRINT) {
        return false
      }

      const instantAccess =
        this.dataUI.offers.PRINT &&
        _find(this.dataUI.offers.PRINT, function(i) {
          return i.instantAccess === true
        })
      return instantAccess || false
    },
    recentlyViewed() {
      return this.$store.getters['s/recentlyviewed']
    }
  },
  created() {},

  mounted() {
    // Skipping any ECard, Gift-card, EGift-card and Pocketmags Plus pages
    if (
      this.$route.path.includes('/ecard') ||
      this.$route.path.includes('/magazines/gift-cards') ||
      this.$route.path.includes('/magazines/e-giftcards') ||
      this.$route.path.includes('/magazines/pocketmagsplus') ||
      this.$route.path.includes('href')
    ) {
      return
    }

    console.log('PDP mounted')
    // Check the has to focus a TAB
    const hash = this.$router?.currentRoute?.hash || undefined
    // console.log('ROUTE HASH=', hash)
    // console.log('local offersTabs=', this.offersTabs)

    if (hash) {
      switch (hash) {
        case '#print':
          this.offersTabs.PRINT.active = true
          break
        case '#international':
          this.offersTabs.INTERNATIONAL.active = true
          break
        case '#digital':
          this.offersTabs.DIGITAL.active = true
          break
        case '#single-issues':
          this.offersTabs.SINGLE_ISSUES.active = true
          break
        default:
          this.offersTabs.PRINT.active = true
      }
    } else {
      // Check the category and subcategory if for a single-issues pattern or digital-magazines
      const obj = this.$route.params.pathMatch
      if (obj) {
        const foundSingleIssues = obj.startsWith('single-issues')
        if (foundSingleIssues) {
          this.offersTabs.SINGLE_ISSUES.active = true
        }
        const foundDigitalMagazines = obj.startsWith('digital-magazines')
        if (foundDigitalMagazines) {
          this.offersTabs.DIGITAL.active = true
        }
        const foundInternational = obj.startsWith('international-offers')
        if (foundInternational) {
          this.offersTabs.INTERNATIONAL.active = true
        }
      }
    }

    this.pushGTMAndFBViewItemEvent({})
  },
  methods: {
    sliceDataForDebug(magazine) {
      return sliceDataForDebug(magazine)
    },
    formatCurrency(value) {
      return formatCurrency(value)
    },
    onSelect(e, index) {
      // e: click event
      // index: index of selected tab
      console.log('Tab clicked')
    },

    handleReviewClick() {
      this.isReview = 1
    },

    async handleClickAction(offer, action, isDefaultOffer) {
      console.log('mixins/product handleClickAction offer=', offer)
      console.log('mixins/product handleClickAction action=', action)
      console.log('mixins/product handleClickAction isDefaultOffer=', isDefaultOffer)
      const item = {
        title: this.dataUI.magazine.title,
        imageUrl: offer.imageUrl,
        productId: this.dataUI.magazine.productId,
        id: this.dataUI.magazine.id,
        sku: this.dataUI.magazine.sku,
        offer,
        category: this.dataUI.magazine.category,
        subCategory: this.dataUI.magazine.subCategory
      }

      // Update the BE Basket data
      const ret = await this.updateBasket(item, action)

      // GTM & FB push events integration
      !ret.error && this.pushGTMAndFBAfterButtonClick({ action, item, isDefaultOffer })

      // Show the basket overlay
      !ret.error && this.$nuxt.$emit('SHOW_BASKET', true)
    },

    // Include or exclude any International offers
    parseInternationalOffers(offers) {
      // console.log('parseInternationalOffers offers=', offers)

      // Return all the offers is the flag DISPLAY_EU_OFFER=true
      if (this.displayEUOffers) return offers

      // Otherwise exclude EU offers
      const ret = offers && offers.filter(offer => offer.zone !== undefined && offer.zone !== 'EU')
      // console.log('parseInternationalOffers internationaOffers=', ret)
      return (ret.length && ret) || undefined
    },
    imgixBannerUrl(url) {
      const originalUrl = url.includes('?') ? url.split('?').shift() : url
      return `${this.$config.imgixURL + originalUrl}?auto=format&fit=fill&fill=solid&bg=0FFF`
    },
    mobileTitle() {
      const title = this.dataUI.magazine.h1Title || this.dataUI.magazine.title || ''
      console.log('mobileTitle title=', title)
      if (title && title.toLowerCase().includes('subscription')) {
        return `${title} offers`
      } else if (title && !title.toLowerCase().includes('subscription')) {
        return `${title}<br />subscription offers`
      }
      return title
    }
  },
  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To chcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    const preloadImageLink = []
    if (this?.dataUI?.magazine?.imageUrl) {
      preloadImageLink.push({
        rel: 'preload',
        as: 'image',
        href: `${this.imgixBannerUrl(this.dataUI.magazine.imageUrl)}&bg=0FFF&w=200`,
        imagesrcset: `${this.imgixBannerUrl(this.dataUI.magazine.imageUrl)}&w=200 1x,
          ${this.imgixBannerUrl(this.dataUI.magazine.imageUrl)}&w=200&dpr=2&q=30 2x,
          ${this.imgixBannerUrl(this.dataUI.magazine.imageUrl)}&w=200&dpr=2&q=30 3x`
      })
    }
    return {
      title: this?.dataUI?.title,
      link: [{ rel: 'canonical', href: this?.dataUI?.magazine?.canonicalURL.replace(/magazine-(\d{4,5})$/g, 'magazine') }, ...preloadImageLink],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this?.dataUI?.magazine?.metaDescription || ''
        },
        {
          name: 'keywords',
          content: this?.dataUI?.magazine?.metaKeywords || ''
        },
        {
          name: 'robots',
          content: this.$config.robots || 'INDEX,FOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this?.dataUI?.title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this?.dataUI?.magazine?.metaDescription
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: this?.dataUI?.magazine?.imageUrl ? `${this.$config.imgixURL}${this.dataUI.magazine.imageUrl}` : 'https://www.magazine.co.uk/icon.png'
        },
        {
          hid: 'og:image:width',
          name: 'og:image:width',
          property: 'og:image:width',
          content: '200'
        },
        {
          hid: 'og:image:height',
          name: 'og:image:height',
          property: 'og:image:height',
          content: '254'
        }
      ]
    }
  },
  jsonld() {
    const faqs = getFAQs({ dataUI: this.dataUI, context: this })

    let faqsPayload = {}
    if (faqs) {
      faqsPayload = getFAQMicrodataJSONLD(faqs)
    }

    return [getPDPMicrodataJSONLD(this.dataUI), getBreadcrumbsMicrodataJSONLD({ breadcrumbs: this.$store.getters['UI/breadcrumbs'] }), faqsPayload]
  }
}
</script>

<style lang="scss">
.isPrint {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  &__bolt {
    margin-left: 10px;
    color: $instant-access;
  }
}
.content-pdp {
  margin: 0 auto;
  flex-flow: row nowrap;
  display: flex;
  max-width: 1230px;
  justify-content: space-around;

  &__main {
    width: 100%;
    flex: 1 1 65%;
    margin: 0 10px;
    padding: 10px;
  }
}
.magazine-top-banner {
  margin-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }
}
.magazine {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  align-items: flex-start;

  align-content: space-between;
  margin-bottom: 20px;
  &__reminderBanner {
    margin: 0 0 20px 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__image {
    position: relative;
    // margin-top: 20px;
    margin-right: 20px;
    min-width: 200px;
    img {
      width: 200px;
      height: auto;
    }
  }
  &__extra {
    flex: 1 1 50%;
    margin: 0 0 10px 0;
  }
  &__top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid $borderColor;
    align-items: center;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    h1 {
      margin: unset;
      flex: 0 1 auto;
      font-size: 22px;
      font-weight: 600;
    }
  }
  &__description {
    line-height: 20px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: #333;
    ul {
      list-style-type: disc;
      margin-left: 0.4em;
      li {
        margin-left: 1em;
      }
    }
    // &--freegift {
    //   display: flex;
    //   align-items: center;
    //   flex-flow: row nowrap;
    //   justify-content: flex-start;
    //   align-content: space-between;
    // }
  }
  &__rating {
    align-items: center;
    // padding: 0 0 10px 0;
    &--mobile {
      display: none !important;
    }
    &--desktop {
      display: inline-flex;
    }
  }
  &__name-and-rating {
    align-items: center;
    display: inline-flex;
  }
  &__offers {
    margin-bottom: 0px !important;
    min-height: 150px;
  }
  &__info {
    margin-bottom: 20px;
    .tab-content ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-left: 40px;
    }
  }
}
.nav-item {
  a,
  a::before,
  a::after {
    box-sizing: unset !important;
    font-size: 14px;
  }
}

.nav-link {
  color: #212529;

  &:hover {
    border-color: $borderColor $borderColor white !important;
    color: #212529 !important;
  }
}
.nav-tabs {
  background: #ededed;
  border-bottom: unset;
}
.nav {
  flex-wrap: nowrap;
}
.tab-content {
  border-radius: 0 0 0.25rem 0.25rem;
  border: 1px solid #dee2e6;
}
.tab-text {
  font-size: 14px;
  p:last-child {
    margin-bottom: 0px;
  }
}
.product {
  &__recentlyViewed {
    .product__offer {
      display: none;
    }
    .actions {
      display: none;
    }
  }
  &__upsell {
    flex: 1 1 100%;
    padding-top: 20px;
    z-index: 100;
    transform: none;
    // width: 400px;
    height: auto;

    position: relative;
    clear: both;
    background-color: #fff;
    text-align: center;
  }
  &__upsell-heading {
    margin: 0 0 10px;
    // letter-spacing: 0.1em;
    font-weight: 700;
    padding: 10px;
  }
}
.upsell-grid {
  .items-list {
    &__item {
      padding: 0px 20px;
      flex: 1 0 20%;
      max-width: 170px;
      min-width: 100px;
      .actions button {
        background-color: transparent;
        text-align: center;
        font-size: 12px;
        color: $buttonAddToCartColor;
        line-height: 12px;
        margin: 0em;
        text-decoration: underline;
        min-width: 100px;
        padding: 15px 0px;
        border: unset;

        &:hover {
          text-decoration: none;
        }
        .spinner {
          top: 40%;
        }
      }
      .view-btn {
        display: none;
      }
      .btn-divider {
        display: none;
      }
    }
  }
}

.pocketmags-only {
  &__content {
    a {
      color: $footerLinkColorHover;
      &:hover {
        color: #000000;
        text-decoration: none;
      }
    }
  }

  &__button {
    margin: 20px 0;
    border: none;
    color: #ffffff;
    width: 100%;
    left: 10pxpx;
    text-decoration: none;
    a {
      background-color: $buttonAddToCartColor;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffffff;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
      border: 1px solid #eee;
      padding: 10px 12px;
    }
  }
}
.mobile {
  display: none;
}
.product__bestSellers {
  display: none;
}
@media (max-width: $screen-md-min) {
  .content-pdp__main {
    flex: 1 1 70%;
  }

  .product__bestSellers {
    display: block;
  }
  .magazine {
    &__reminderBanner {
      margin: 0 0 10px 0;
      img {
        width: 100%;
      }
    }
    &__offers {
      min-height: 350px;
    }
  }
}

@media (max-width: 350px) {
  .magazine {
    &__image {
      img {
        width: 120px !important;
      }
    }
  }
}

@media (max-width: $screen-sm-min) {
  .mobile {
    .add-to-basket-pdp--mobile {
      color: white;
      padding: 10px !important;
      margin: 10px !important;
      max-width: 95% !important;
    }
  }
  .content-pdp__main {
    flex: 0 1 100%;
    margin: unset;
    padding: 0px 20px;
  }
  .upsell-grid .items-list__item {
    padding: 0px 10px;
  }
  .magazine {
    align-items: flex-start;
    margin: 10px 10px 20px 10px !important;
    &__reminderBanner {
      margin: 0 0px 10px 0px;
      img {
        width: 100%;
        min-height: 53px;
      }
    }
    &__image {
      min-width: 100px;
      padding: 0;
      img {
        width: 150px !important;
      }
    }
    &__sub-heading-bar {
      padding: 10px;
      background: $categoryHeadingBg;
      h2 {
        color: #fff;
        text-transform: uppercase;
        font-size: 18px;
        margin: 0;
        font-weight: 500;
        text-align: center;
      }
    }
    &__top {
      padding: 20px 0;
      h1 {
        text-align: center;
      }
      img {
        max-width: 150px;
      }
    }

    &__extra {
      margin: 0 10px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .content-pdp {
    &--main {
      flex: 0 1 100%;
      margin: 0;
      padding: 0px;
    }
    &--description {
      h1 {
        line-height: 1.2em;
      }
    }
  }
  .nav-link {
    font-size: 16px;
    padding: 0.5rem 0.5rem;
  }
  .content--description {
    h1 {
      line-height: 40px;
    }
  }
  .tab-content {
    border-radius: unset;
    border: unset;
    border-bottom: 1px solid #dee2e6;
  }
  .p-4 {
    padding: 12px !important;
  }
  .desktop {
    display: none;
  }
  .nav-item {
    font-size: 16px;
    .nav-link {
      line-height: 31px;
      min-height: 31px;
    }
  }
  .upsell-grid .items-list__item {
    margin: 0px 15px 15px 15px;
    flex: 1 0 30%;
    max-width: 170px;
    min-width: 100px;
  }
  // .offer-payment-type {
  //   &--cc {
  //     max-width: 200px;
  //   }
  //   &--dd {
  //     padding: 10px 0;
  //     max-width: 80px;
  //   }
  // }
  .magazine-top-banner {
    margin: 5px 0;
  }
  .mobile {
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
  }

  .magazine {
    margin-top: 20px;
    .add-to-basket-pdp {
      @include button-add-to-cart;
      margin: 5px;
      padding: 5px !important;
      max-width: 180px !important;
      font-size: 16px;
      background-color: $buttonAddToCartColor !important;
    }
    h1 {
      margin-left: 10px;
    }
    align-items: flex-start;
    &__image {
      margin: unset;
      min-width: 90px;

      margin: 0 5px 0 5px;
      img {
        width: 100%;
      }
    }
    &__top {
      border-bottom: unset;
      margin-bottom: unset;
    }

    &__extra {
      margin: 0 0 0 10px;
    }
    &__info {
      .tab-text ul {
        list-style-type: disc;
        padding-left: 20px;
      }
    }
  }
}
@media (max-width: 414px) {
  .content-pdp__main {
    flex: 0 1 100%;
    margin: unset;
    padding: unset;
  }
}

/* ==========================================================================
    Internet Explorer Section
   ========================================================================== */
/*  IE10+ */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .content-pdp {
    &__main {
      width: 100%;
      max-width: 960px;
      flex: 1 1 auto;
    }
  }
}
.alert-warning {
  font-size: 14px;
  a {
    color: #856404;
    text-decoration: underline;
  }
}

/* iPhone 14 Pro Max - 1284×2778 pixels at 460ppi */
/* iPhone 13 */
@media only screen and (max-device-width: 430px) and (max-device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
  .content-pdp__main {
    padding: 0px !important;
  }
  .magazine {
    h1 {
      font-size: 17px !important;
    }
  }
}
</style>
