<template>
  <div class="footer-links">
    <div class="footer-links-grid content--max">
      <div class="footer-links-grid__item">
        <div><strong>Useful links</strong></div>
        <div>
          <a href="/the-hub" title="The Hub" target="_blank" rel="noopener" @click.prevent="handleTheHubClick">News &amp; Blog</a>
        </div>
        <div><a title="Best selling magazines" href="/best-selling-magazine-subscriptions">Best selling magazines</a></div>
        <div><a title="Magazines for women" href="/magazine-subscriptions-for-women">Magazines for women</a></div>
        <div><a title="Magazines for men" href="/magazine-subscriptions-for-men">Magazines for men</a></div>
        <div><a title="Sale offers" href="/discounted-magazine-sale-offers">Sale offers</a></div>
        <div><a title="Gift Subscriptions" href="/magazine-subscription-gift-ideas">Gift Subscriptions</a></div>
        <div><a title="Digital magazines" href="https://pocketmags.com/" target="_blank" rel="noopener">Digital magazines</a></div>
        <div><a title="What is a magazine subscription" href="/why-a-magazine-subscription ">What is a magazine subscription</a></div>
      </div>

      <div class="footer-links-grid__item">
        <div><strong>Help</strong></div>
        <div><a title="FAQs" href="/frequently-asked-questions">FAQs</a></div>
        <!-- <div><a title="Christmas FAQs" href="/christmas-faq">Christmas FAQs</a></div> -->
        <div><a title="Terms and Conditions" href="/terms-and-conditions">Terms &amp; Conditions</a></div>
        <div><a title="Delivery" href="/delivery-policy">Delivery</a></div>
        <!-- <div><a title="Gift orders" href="/delivery-policy">Gift orders</a></div> -->
        <div><a title="Bulk orders &amp; Corporate sales" href="/corporate-sales">Bulk orders &amp; Corporate sales</a></div>
        <div><a title="Contact us" href="/contact">Contact us</a></div>
        <div><a title="Sitemap" href="/sitemap">Sitemap</a></div>
        <div><a href="https://order-tracker.magazine.co.uk/#/" target="_blank" title="Order Tracker" rel="noopener">Order Tracker</a></div>
      </div>

      <div class="footer-links-grid__item">
        <div><strong>Our Other Sites</strong></div>
        <div><a title="Jellyfish" href="https://www.jellyfish.com" target="_blank" rel="noopener">Jellyfish</a></div>
        <div><a title="Pocketmags" href="https://pocketmags.com/" target="_blank" rel="noopener">Pocketmags</a></div>
      </div>

      <div class="footer-links-grid__item">
        <div><strong>Corporate</strong></div>
        <div><a title="About Us" href="/about-us">About us</a></div>
        <div><a title="Privacy Policy" href="/privacy-notice">Privacy Policy</a></div>
        <div><a title="California & US Privacy Info" href="/california-and-us-privacy-info">California & US Privacy Info</a></div>
        <div><a title="Cookie Policy" href="/cookie-policy">Cookie Policy</a></div>
        <div><a title="My Privacy Choices" href="#" onclick="window.Cookiebot.show()">My Privacy Choices</a></div>
        <div><a title="Jellyfish" href="https://www.jellyfish.com" target="_blank" rel="noopener">Jellyfish</a></div>
      </div>

      <div class="footer-links-grid__item">
        <div><strong>Sell with us</strong></div>
        <div><a title="Publisher FAQs" href="/publishers">Publisher FAQs</a></div>
        <div><a title="Apply to sell" href="/publishers">Apply to sell</a></div>
        <div><a title="Affiliates" href="/affiliates">Affiliates</a></div>
      </div>

      <div class="footer-links-grid__item">
        <div>© {{ year }} magazine.co.uk</div>
        <div>
          <img :src="`${$config.imgixURL}/wysiwyg/footercards.png?auto=format&w=231`" width="210px" height="75px" alt="Accepted Payments" loading="lazy" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterLinks',
  serverCacheKey() {
    return 'FooterLinks'
  },
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  methods: {
    dataPush(events) {
      console.log(this.$ga)
      console.log(events)
    },
    handleTheHubClick() {
      this.$config.UAEESyntax && this.$gtm && this.$gtm.push({ event: 'thehub' })
      // Redirct to the The Hub page
      window.location.href = `${this.$config.baseURL}/the-hub`
    }
  }
}
</script>

<style lang="scss">
.footer-links {
  font-size: 14px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  .footer-links-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    &__item {
      padding: 20px 20px;
      a {
        color: $footerLinkColor;
        text-decoration: none;
        &:hover {
          color: $footerLinkColorHover;
        }
      }
      img {
        max-width: 210px;
        height: auto;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .footer-links {
    padding: 10px;
  }
  .footer-links-grid {
    justify-content: space-around !important;
    &__item {
      margin-top: 20px;
      font-size: 18px;
      overflow: hidden;
      flex: 0 0 46%;
      padding: 0px !important;
      align-content: center;
      div {
        // background: yellow;
        margin: 1px;
        font-size: 16px;
        min-height: 40px;
        a {
          padding: 11px 0px;
          font-size: 16px;
          vertical-align: -webkit-baseline-middle;
          display: inline-block;
          width: 100%;
          height: 100%;
          &:active,
          &:visited,
          &:hover {
            color: #474747 !important;
          }
        }
        strong {
          font-size: 18px;
          vertical-align: -webkit-baseline-middle;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
    &__item:last-child {
      padding-top: 10px !important;
    }
    img {
      width: 100%;
      max-width: 160px;
    }
  }

  .footer-up {
    &__grid {
      flex-flow: column nowrap;
      height: 100%;
    }
  }
}
</style>
