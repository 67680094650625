<template>
  <div>
    <div class="desktop" style="content-visibility: auto;">
      <HomepageNavDesktop :timestamp="timestamp" />
    </div>
    <div class="mobile homepage-nav" style="content-visibility: auto;">
      <a
        v-for="item in sitelinksmobile"
        :key="`top6Sitelinks-${item.key}`"
        class="homepage-nav__item rad5"
        :href="`${$config.baseURL}${item.href}`"
        :title="item.text"
        :alt="item.alt"
      >
        {{ item.text }}
        <span class="homepage-nav__chevron--right"><font-awesome-icon :icon="['fal', 'chevron-right']" class="fa fa-w-10"/></span>
      </a>
      <div class="homepage-nav__item homepage-nav__showmore" @click="show">
        View All Categories
        <span v-if="!showMore"><font-awesome-icon :icon="['fal', 'plus']" class="fa fa-w-10"/></span>
        <span v-else><font-awesome-icon :icon="['fal', 'minus']" class="fa fa-w-10"/></span>
      </div>
      <div v-if="showMore" class="homepage-nav__bottom">
        <a v-for="(cat, index) in enabledCats" :key="index" class="homepage-nav__item homepage-nav__item--showmore" :href="cat.href">
          <span class="homepage-nav__item-icon"><font-awesome-icon :icon="['fal', cat.icon]" class="fa fa-lg"/></span>
          {{ cat.title }}
          <span class="homepage-nav__chevron--right"><font-awesome-icon :icon="['fal', 'chevron-right']" class="fa fa-w-10"/></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomepageNav',
  serverCacheKey: props => `HomepageNav::${props.timestamp}`,
  components: {
    HomepageNavDesktop: () => import('~/components/Homepage/HomepageNavDesktop.vue')
  },
  props: {
    timestamp: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showMore: false,
      sitelinksmobile: this.$store.getters['utility/sitelinksmobile'],
      cats: [
        // {
        //   title: 'Christmas Gift Ideas',
        //   href: '/christmas-gift-ideas',
        //   icon: 'gift',
        //   enabled: true
        // },
        {
          title: 'Magazines for Women',
          href: '/magazine-subscriptions-for-women',
          icon: 'female',
          enabled: true
        },
        {
          title: 'Magazines for Men',
          href: '/magazine-subscriptions-for-men',
          icon: 'male',
          enabled: true
        },
        {
          title: 'Kids',
          href: '/kids-magazines',
          icon: 'child',
          enabled: true
        },
        {
          title: 'Best Selling Magazines',
          href: '/best-selling-magazine-subscriptions',
          icon: 'award',
          enabled: true
        },
        {
          title: 'Sale Offers',
          href: '/discounted-magazine-sale-offers',
          icon: 'tag',
          enabled: true
        },
        {
          title: 'Gift Subscription Ideas',
          href: '/magazine-subscription-gift-ideas',
          icon: 'gift',
          enabled: true
        },
        {
          title: 'Hobbies & Leisure',
          href: '/hobbies-and-leisure-magazines',
          icon: 'camera',
          enabled: true
        },
        {
          title: 'Sports',
          href: '/sports-magazines',
          icon: 'running',
          enabled: true
        },
        {
          title: 'Home & Gardening',
          href: '/home-and-gardening-magazines',
          icon: 'flower-daffodil',
          enabled: true
        },
        {
          title: 'Motoring & Transport',
          href: '/motoring-and-transport-magazines',
          icon: 'car',
          enabled: true
        },
        {
          title: 'Entertainment',
          href: '/entertainment-magazines',
          icon: 'gamepad',
          enabled: true
        },
        {
          title: 'Lifestyle',
          href: '/lifestyle-magazines',
          icon: 'heart',
          enabled: true
        },
        {
          title: 'Arts & Culture',
          href: '/arts-and-culture-magazines',
          icon: 'paint-brush-alt',
          enabled: true
        },
        {
          title: 'Science & Nature',
          href: '/science-and-nature-magazines',
          icon: 'paw',
          enabled: true
        },
        {
          title: 'Food & Drink',
          href: '/food-and-drink-magazines',
          icon: 'utensils',
          enabled: true
        },
        {
          title: 'News & Current Affairs',
          href: '/news-and-current-affairs-magazines',
          icon: 'newspaper',
          enabled: true
        },
        {
          title: 'Computers & Tech',
          href: '/computers-and-technology-magazines',
          icon: 'laptop',
          enabled: true
        },
        {
          title: 'Fashion & Beauty',
          href: '/fashion-and-beauty-magazines',
          icon: 'shopping-bag',
          enabled: true
        },
        {
          title: 'Business & Finance',
          href: '/business-and-finance-magazines',
          icon: 'chart-line',
          enabled: true
        },
        {
          title: 'Digital Only Subscriptions',
          href: '/digital-magazines',
          icon: 'phone-laptop',
          enabled: true
        },
        {
          title: 'Magazines with Free Gifts',
          href: '/magazines-with-free-gifts',
          icon: 'gifts',
          enabled: true
        },
        {
          title: 'Gift Card',
          href: '/gift-card-options',
          icon: 'gift-card',
          enabled: true
        },
        {
          title: 'Gift Finder',
          href: '/gift_finder',
          icon: 'gift',
          enabled: true
        },
        {
          title: 'Single Issues',
          href: '/single-issues',
          icon: 'file',
          enabled: false
        },
        {
          title: 'Instant Digital Access',
          href: '/magazines-with-free-digital-access',
          icon: 'phone-laptop',
          enabled: true
        },
        {
          title: 'Christmas FAQs',
          href: '/christmas-faq',
          icon: 'question',
          enabled: false
        },
        {
          title: 'International',
          href: '/international-offers',
          icon: 'globe-americas',
          enabled: true
        }
      ]
    }
  },
  computed: {
    enabledCats() {
      return this.cats.filter(obj => {
        return obj.enabled === true
      })
    }
  },
  methods: {
    show() {
      this.showMore = !this.showMore
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile {
  display: grid !important;
}
.desktop {
  display: none !important;
}
.homepage-nav {
  display: grid;
  background-color: #e7ecf2;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 6px;
  padding: 10px 6px 10px 6px;
  &__item {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    background-color: #fff;
    font-weight: 700;
    display: inline-block;
    width: 100%;
    padding-left: 10px;
    color: #333;
    text-decoration: none;
    box-sizing: border-box;
    text-transform: capitalize;
    &--showmore {
      border-top: 1px #d9d9d9 solid;
      padding-left: 10px;
      height: 48px;
    }
  }
  &__chevron--right {
    color: black;
    float: right;
    margin: 0 8px 0 5px;
  }
  &__showmore {
    text-align: center;
    grid-column: span 2;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    span {
      margin: 0 5px;
      color: black;
    }
  }
  &__bottom {
    margin-top: -6px;
    grid-column: span 2;
  }
  &__item-icon {
    width: 25px;
    text-align: center;
    color: black;
    float: left;
    margin-right: 10px;
  }
}

@media (min-width: $screen-sm-min) {
  .mobile {
    display: none !important;
  }
  .desktop {
    display: flex !important;
  }
}

@media (max-width: 400px) {
  .homepage-nav {
    &__item {
      font-size: 14px;
    }
  }
}
</style>
