<template>
  <section class="content content-search">
    <div v-if="products" class="content-search__main">
      <client-only v-if="$store.getters['UI/debug']">
        <ul class="debug__list">
          <li class="debug__item">
            <tree-view :data="queryString" :options="{ rootObjectKey: 'QueryString', maxDepth: 0, link: true }"> </tree-view>
          </li>
          <li class="debug__item">
            <tree-view :data="query" :options="{ rootObjectKey: 'Filter Query', maxDepth: 0, link: true }"> </tree-view>
          </li>
          <li class="debug__item">
            <tree-view :data="products" :options="{ rootObjectKey: 'Products', maxDepth: 0, link: true }"> </tree-view>
          </li>
        </ul>
      </client-only>

      <div class="search-head">
        <h1>{{ title }}</h1>
      </div>

      <div v-if="categories" class="search-head">
        <h5>Categories:</h5>
      </div>
      <div class="search-categories">
        <div v-for="(cat, index) in categories" :key="index" class="search-categories__wrapper">
          <a v-if="cat.level == 2" :href="`${$config.baseURL}/${cat.url}`">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="search-categories__suggest-term" v-html="cat['@search.text']"> </span>
          </a>
          <a v-else-if="cat.level == 3" :href="`${$config.baseURL}/${cat.url}`">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="search-categories__suggest-term" v-html="cat['@search.text']"> </span>
            <span v-if="cat.level == 3" class="search-categories__suggest-cat"> in {{ cat.category1 }}</span>
          </a>
        </div>
      </div>
      <div class="search-top">
        <div class="search-filters m-md-2">
          <div class="search-head">Magazines:</div>
        </div>
        <div v-if="$store.getters['UI/debug']" class="search-sort">
          <b-dropdown right variant="link" class="m-md-2" toggle-class="text-decoration-none" no-caret>
            <template #button-content> Sort: {{ sortLabel }}&nbsp;&nbsp;<font-awesome-icon :icon="['fal', 'chevron-down']" class="fa" /> </template>
            <b-dropdown-item :href="`${getSearchURL()}&name=asc`" :active="sortLabel == 'Name (A to Z)'" aria-describedby="Name (A to Z)">Name (A to Z)</b-dropdown-item>
            <b-dropdown-item :href="`${getSearchURL()}&name=desc`" :active="sortLabel == 'Name (Z to A)'" aria-describedby="Name (Z to A)">Name (Z to A)</b-dropdown-item>
            <b-dropdown-item :href="`${getSearchURL()}&price=asc`" :active="sortLabel == 'Price (Low to High)'" aria-describedby="Price (Low to High)">
              Price (Low to High)
            </b-dropdown-item>
            <b-dropdown-item :href="`${getSearchURL()}&price=desc`" :active="sortLabel == 'Price (High to Low)'" aria-describedby="Price (High to Low)">
              Price (High to Low)
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="category-products items-list search-list">
        <ResultsProducts v-for="item in products" :key="item.productId" :type="'search'" :item="item" class="search-list__item items-list__item" />
      </div>
    </div>
    <div v-else class="content-search__main">
      <div class="search-head">
        <h1>{{ title }}</h1>
      </div>
      <div class="search-no-results">Your search returns no results</div>
    </div>
  </section>
</template>

<script>
// import LazyHydrate from 'vue-lazy-hydration'
import { mapState, mapGetters } from 'vuex'
import { azureSearch, createAzureSearchQuery, getOrderbyAzureSearchOption } from '~/lib/util/azuresearch'
import { sendSSAPIPageView, createUniqueFBEventId } from '~/lib/util/fb'
import { getGTMPageViewDetailsPayload } from '~/lib/util/gtmUAEE'

import offlineData from '~/offlineData/search.js'

export default {
  name: 'Search',

  components: {
    // LazyHydrate,
    ResultsProducts: () => import('~/components/common/list/Product.vue')
  },
  layout: 'default',
  async fetch() {
    // Look for an available Query String and retrieve the URL path
    const path = this.$route.path
    const query = this.$nuxt.context.query
    const useCache = this.$store.getters['UI/useCache']
    console.log('page=Search')
    console.log('path=', path)
    console.log('query=', query)
    console.log('QS useCache=', useCache)

    this.queryString = query

    // Update title
    this.$store.commit('search/setTitle', { title: `Search results for '${query.q}'` })
    this.$store.commit('search/setQuery', createAzureSearchQuery({ query }))

    // Breadcrumbs
    const page = {
      value: this.$store.state.search && this.$store.state.search.title,
      url: '',
      type: 'search'
    }
    this.$store.commit('ADD_BREADCRUMBS', { path, page })

    // Get the orderby search option
    const orderby = query && getOrderbyAzureSearchOption({ query, isSearchResult: true })

    this.$config.debug && console.log('this.$config.UseOfflineData=', this.$config.UseOfflineData)
    this.$config.debug && console.log('process.env.USE_OFFLINE_DATA=', process.env.USE_OFFLINE_DATA)

    if (this.$config.UseOfflineData) {
      // Use OFFILE data ingegration
      const pageData = await offlineData
      pageData.categories.value && this.$store.commit('search/setCategories', pageData.categories.value)
      pageData.products.value && this.$store.commit('search/setProducts', pageData.products.value)
      this.$store.commit('search/setCount', pageData.products['@odata.count'])
      console.log('OFFLINE search=', pageData)
    } else if (query.q === undefined) {
      this.$store.commit('search/setProducts', [])
    } else {
      await azureSearch({ searchTerm: query && query.q.trim(), orderby }, (error, data) => {
        if (data) {
          data.categories.value && this.$store.commit('search/setCategories', data.categories.value)
          data.products.value && this.$store.commit('search/setProducts', data.products.value)
          this.$store.commit('search/setCount', data.products['@odata.count'])
        } else if (error) {
          this.$store.commit('search/setProducts', [])
        }
      })
    }
  },

  fetchOnServer: true,
  // multiple components can return the same `fetchKey` and Nuxt will track them both separately
  // fetchKey: 'search',
  // Set the minimum executing time in milliseconds (to avoid quick flashes)
  // fetchDelay: 5000,

  data() {
    return {
      queryString: []
    }
  },

  computed: {
    sortLabel() {
      if (this.query.price === false && this.query.name === 'asc') {
        return 'Name (A to Z)'
      }
      if (this.query.price === false && this.query.name === 'desc') {
        return 'Name (Z to A)'
      }
      if (this.query.name === false && this.query.price === 'asc') {
        return 'Price (Low to High)'
      }
      if (this.query.name === false && this.query.price === 'desc') {
        return 'Price (High to Low)'
      }
      return 'Name (A to Z)'
    },
    ...mapState('search', ['title', 'products', 'query']),
    ...mapGetters({
      title: 'search/title',
      products: 'search/products',
      categories: 'search/categories',
      query: 'search/query'
    })
  },

  watchQuery: ['q', 'price', 'name'],

  mounted() {
    // Facebook Event Deduplication
    const eventId = createUniqueFBEventId('PageView')

    // Customer email address
    const email = this.$store.getters['s/email']

    // UAEE tracking PageView
    this.$config.UAEESyntax &&
      this.$gtm.push(
        getGTMPageViewDetailsPayload({ pageUrl: this.$route.path, pageTitle: (this.page && this.page.title) || this.title, routeName: this.$route.name, eventId, email })
      )

    // FB v12.0
    const eventSourceUrl = `${this.$config.baseURL}${this.$route.fullPath}`

    // Facebook Server-side API PageView
    sendSSAPIPageView({ context: this, parameters: { eventId, eventSourceUrl } })
  },
  methods: {
    getSearchURL() {
      return `${this.$config.baseURL}/search/?q=${this.query.q}`
    }
  },

  // To check more options, link https://github.com/nuxt/vue-meta#script-object
  // To thcek how it is working https://nuxtjs.org/faq/duplicated-meta-tags/
  head() {
    return {
      title: this.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.metaDescription
        },
        {
          name: 'keywords',
          content: this.metaKeywords || ''
        },
        {
          name: 'robots',
          content: 'NOINDEX,NOFOLLOW'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.metaDescription
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: 'magazine.co.uk'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: 'https://www.magazine.co.uk/icon.png'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.search-head {
  h5 {
    font-size: 16px;
    color: #474747;
    font-weight: 400;
    margin-left: 8px;
    margin-bottom: 5px;
  }
}
.search-categories {
  font-size: 14px;
  font-weight: 400;
  color: #474747;
  display: grid;
  line-height: 30px;
  a {
    margin-left: 15px;
    color: #474747;
    &:hover {
      color: #ff0d00;
    }
  }
  &__wrapper {
    padding: 5px 0px;
  }
}
.view-btn {
  display: none;
}
a.product__text div.product__offer {
  display: block;
}
.content-search {
  margin: 0 auto;
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-around;
  max-width: 1230px !important;
  width: 100%;

  &__main {
    flex: 1 1 100%;
    margin: 0 10px;
  }
}
.search {
  &-head {
    h1 {
      font-size: 28px;
      margin: 0 0 15px;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      color: #ff0d00;
    }
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-no-results {
    font-size: 22px;
    text-align: center;
    margin: 20px;
  }
  &-filters {
    color: #474747;
    text-decoration: none;
    &__count {
      font-weight: 700;
    }
  }
}

.nav-link {
  color: #212529;

  &:hover {
    border-color: $borderColor $borderColor white !important;
    color: #212529 !important;
  }
}
.nav-tabs {
  background: #ededed;
  border-bottom: unset;
}
.nav {
  flex-wrap: nowrap;
}
.tab-content {
  border-radius: 0 0 0.25rem 0.25rem;
  border: 1px solid #dee2e6;
}
.tab-text {
  font-size: 14px;
  p:last-child {
    margin-bottom: 0px;
  }
}
.product {
  &__recentlyViewed {
    .product__offer {
      display: none;
    }
    .actions {
      display: none;
    }
  }
}
.search-list {
  .items-list__item .actions button {
    margin-top: 10px;
    @include button-add-to-cart;
  }
}

@media (max-width: $screen-sm-min) {
  .content__main {
    flex: 0 1 100%;
    margin: unset;
    padding: unset;
  }
  .search-list {
    .items-list__item {
      flex: 0 0 24.5%;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .content {
    &--main {
      flex: 0 1 100%;
      margin: 0;
      padding: 0px;
    }
    &--description {
      h1 {
        line-height: 1.2em;
      }
    }
  }

  .search-list {
    .items-list__item {
      flex: 0 0 45%;
    }
  }
  .search {
    &-head {
      margin-top: 20px;
      h1 {
        font-size: 22px;
        margin: 0 0 15px;
        text-align: center;
      }
    }
    &-top {
      display: flex;
      justify-content: space-between;
      margin: 10px 20px 10px 0px;
    }
    &-no-results {
      font-size: 22px;
      text-align: center;
      margin: 20px;
    }
    &-filters {
      margin: 10px 0px 10px 20px;
    }
  }
}

@media (max-width: 414px) {
  .search-head {
    h5 {
      margin-left: 20px;
    }
  }
  .search-categories {
    a {
      min-height: 44px;
      margin-left: 30px;
      // border-bottom: 1px solid gray;
      line-height: 44px;
    }
  }
}
/* ==========================================================================
    Internet Explorer Section
   ========================================================================== */
/*  IE10+ */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .content {
    &__main {
      width: 100%;
      max-width: 960px;
      flex: 1 1 auto;
    }
  }
}
</style>
